import React from "react";
// reactstrap components
// import {
// } from "reactstrap";

// core components
import Navbar from "../components/Navbars/NewNavbar";
import Homeheader from "../components/Headers/HomeHeader";
import DarkFooter from "../components/Footers/DarkFooter";
import {Helmet} from "react-helmet";

// sections for this page


function Home () {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
    <Helmet>
        <title>Home | Airport Residency | Hotel in Mahipalpur | Hotel Near IGI Airport</title>
        <meta name="description" content="Airport Residency is located just next to international airport with a wonderful view of landing runway. Beautifully designed hotel in 3.5 acre of lush green land over looking the Airport gives you a feeling of openness, freshness and a home away from home." />
        <meta name="keywords" cpntent="Airport Residency, Hotel in Mahipalpur, Hotel Near IGI Airport, Hotel in Delhi, Hotel Near IGI Airport" />
      </Helmet>
      <Navbar />
      <div className="wrapper">
        <Homeheader />
        <DarkFooter />
      </div>
    </>
  );
}

export default Home;