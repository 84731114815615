import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
// core components
import Navbar from "../components/Navbars/FixedNav";
import DarkFooter from "../components/Footers/DarkFooter";
import {Helmet} from "react-helmet";

// core components
const items = [

    {
      src: require("assets/img/Gallery/15.jpg")
    },
    {
      src: require("assets/img/Gallery/16.jpg")
    },
    {
      src: require("assets/img/Gallery/17.jpg")
    },
    {
      src: require("assets/img/Gallery/53.jpg")
    },
    {
      src: require("assets/img/Gallery/49.jpg")
    },
    {
      src: require("assets/img/Gallery/51.jpg")
    },
    {
      src: require("assets/img/Gallery/55.jpg")
    },
    {
      src: require("assets/img/Gallery/56.jpg")
    },
    {
      src: require("assets/img/Gallery/57.jpg")
    },
    {
      src: require("assets/img/Gallery/59.jpg")
    },
    {
      src: require("assets/img/Gallery/61.jpg")
    },
    {
      src: require("assets/img/Gallery/62.jpg")
    },
    {
      src: require("assets/img/Gallery/63.jpg")
    },
    {
      src: require("assets/img/Gallery/64.jpg")
    },
    {
      src: require("assets/img/Gallery/70.jpg")
    },
    {
      src: require("assets/img/Gallery/67.jpg")
    },
    {
      src: require("assets/img/Gallery/29.jpg")
    },
    {
      src: require("assets/img/Gallery/25.jpg")
    },
    
    {
      src: require("assets/img/Gallery/71.jpg")
    },
    {
      src: require("assets/img/Gallery/77.jpg")
    },
    {
      src: require("assets/img/Gallery/01.jpg")
    },
    {
      src: require("assets/img/Gallery/03.jpg")
    },
    {
      src: require("assets/img/Gallery/05.jpg")
    },
    {
      src: require("assets/img/Gallery/33.jpg")
    },
    {
      src: require("assets/img/Gallery/34.jpg")
    },
    {
      src: require("assets/img/Gallery/81.jpg")
    },
    {
      src: require("assets/img/Gallery/82.jpg")
    },
    {
      src: require("assets/img/Gallery/84.jpg")
    },
    {
      src: require("assets/img/Gallery/85.jpg")
    },
    {
      src: require("assets/img/Gallery/07.jpg")
    },
    {
      src: require("assets/img/Gallery/08.jpg")
    },
  ];
  
  function CarouselSection() {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
      setAnimating(true);
    };
    const onExited = () => {
      setAnimating(false);
    };
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };  React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
      });


    return (
      <>
         <Helmet>
        <title>Hotel Gallery | Airport Residency | Hotel in Mahipalpur | Hotel Near IGI Airport</title>
        <meta name="description" content="Airport Residency is located just next to international airport with a wonderful view of landing runway. Beautifully designed hotel in 3.5 acre of lush green land over looking the Airport gives you a feeling of openness, freshness and a home away from home." />
        <meta name="keywords" cpntent="Airport Residency, Hotel in Mahipalpur, Hotel Near IGI Airport, Hotel in Delhi, Hotel Near IGI Airport" />
      </Helmet>
            <Navbar />

        <div className="section" id="carousel">
          <Container>
            <div className="title">
                <br/>
                <br/>
                <br/>
              <h2 align="center">Hotel Gallery</h2>
            </div>
            <Row className="justify-content-center">
              <Col lg="8" md="12">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
        <DarkFooter />

      </>
    );
  }
  
  export default CarouselSection;