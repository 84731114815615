import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle
} from "reactstrap";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 10;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/contact-header.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">

        <Container>
        <div class="row">
        <div class="col-md-3 ml-auto mr-auto text-center">
          <br/>
          <br/>
          <br/>
  <h2 class="title">Reach Us</h2>
</div>
        <div class="col-md-6 ml-auto mr-auto text-justify">
        <h3 class="title">Airport Residency</h3>
         <p>Dwarka Link Road, Off NH 8, Old Gurgaon Road,<br/>Samalkha, New Delhi - 110037<br/>Front office : (+91 - 11 ) 47 12 22 22, 47 12 22 25<br/>Reservations : (+91 - 11) 47 12 22 24<br/>Accounts: (+91 - 11) 47 12 22 26 / 47 12 22 26</p>
         <a href='https://www.google.com/maps/place/Airport+Residency/@28.537599,77.0916939,14z/data=!4m5!3m4!1s0x390d1bf6c03f40ab:0xf7cdc5fe939b5d9d!8m2!3d28.5363926!4d77.0994187?hl=en-GB' target="_blank" class="fas fa-map-marked"> Locate On Google Map</a>
         </div>
         </div>
         </Container>
         </div>
        </div>



    </>
  );
}

export default LandingPageHeader;
