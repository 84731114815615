import React from "react";
// reactstrap components
// import {
// } from "reactstrap";

// core components
import Navbar from "../components/Navbars/FixedNav";
import DarkFooter from "../components/Footers/DarkFooter";
import {Helmet} from "react-helmet";

// sections for this page


function Aboutus () {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
 <Helmet>
        <title>About Us | Airport Residency | Hotel in Mahipalpur | Hotel Near IGI Airport</title>
        <meta name="description" content="Airport Residency is located just next to international airport with a wonderful view of landing runway. Beautifully designed hotel in 3.5 acre of lush green land over looking the Airport gives you a feeling of openness, freshness and a home away from home." />
        <meta name="keywords" cpntent="Airport Residency, Hotel in Mahipalpur, Hotel Near IGI Airport, Hotel in Delhi, Hotel Near IGI Airport" />
      </Helmet>
      <Navbar />
      <div className="wrapper">
      <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>        <div class="col-md-11 ml-auto mr-auto text-right">
 
 <h2 class="title">About Us</h2>
         </div>
<div class="col-md-11 ml-auto mr-auto text-justify">
       <p>Airport Residency is located just next to international airport with a wonderful view of landing runway. Beautifully designed hotel in 3.5 acre of lush green land over looking the Airport gives you a feeling of openness, freshness and a home away from home.
         <br/>
         <br/>
         Located just 7 km from international airport and 15 km form domestic airport.  Airport Residency is just at the right place for Business Travelers. The 32 air-conditioned rooms at Airport Residency are comfortable and spacious, complete with modern amenities. Rooms are divided into 18 Deluxe Rooms and 14 Luxury Rooms.
         <br/>
         <br/>
         <ul>
        <li>32 inch LCD television</li>
        <li>Tea/Coffee Maker</li>
        <li>Iron and Iron Board</li>
        <li>Mini Bar</li>
        <li>Safe/Locker</li>
        <li>Internet connection</li>
        <li>Full Wifi Zone</li>
       </ul>
         Modern Facilities, Perfect Location and well Trained Staff makes is just a right place for you and your guests to stay with us. Just next to 8 lane Delhi-Gurgoan Expressway makes Delhi and Gurgoan conveniently accessible.
         <br/></p>
       <p class="text-right"> <i class="fas fa-tv"></i>  <i class="fas fa-mug-hot"></i>  <i class="fas fa-glass-cheers"></i>  <i class="fab fa-internet-explorer"></i>  <i class="fas fa-wifi"></i>  <i class="fab fa-accessible-icon"></i>
         </p>
  </div>
  <DarkFooter />

        <DarkFooter />
      </div>
    </>
  );
}

export default Aboutus;