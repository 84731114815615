import React from "react";
// reactstrap components
// import {
// } from "reactstrap";

// core components
import Navbar from "../components/Navbars/FixedNav";
import DarkFooter from "../components/Footers/DarkFooter";
import {Helmet} from "react-helmet";

// sections for this page


function Room () {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
   <Helmet>
        <title>Rooms | Airport Residency | Hotel in Mahipalpur | Hotel Near IGI Airport</title>
        <meta name="description" content="Airport Residency is located just next to international airport with a wonderful view of landing runway. Beautifully designed hotel in 3.5 acre of lush green land over looking the Airport gives you a feeling of openness, freshness and a home away from home." />
        <meta name="keywords" cpntent="Airport Residency, Hotel in Mahipalpur, Hotel Near IGI Airport, Hotel in Delhi, Hotel Near IGI Airport" />
      </Helmet>
      <Navbar />
      <div className="wrapper">
        <div class="col-md-11 ml-auto mr-auto text-right">
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <h2 class="title">Rooms</h2>
         </div>
<div class="col-md-11 ml-auto mr-auto text-justify">
       <p>Airport Residency has elegant lavishly furnished 18 deluxe & 14 luxury rooms, equipped with all modern facilities like Attached bathroom, Running hot and cold water, Bathtub in Luxury rooms, Hairdryer, Bathrobe, Same Day laundry services, LCD TV with Dish Connection, Direct Dial Telephone, Tea/coffee maker, Complementary fruit basket on Arrival, Toiletries, Refrigerator, Room service, Doctor-On-Call, Internet Facilities.
         <br/>
         <br/>
         The beauty of these rooms is that they have view into charming private gardens and maintain mini bars to tickle the palate and created mood. All rooms here are non-smoking Rooms. They are centrally air-conditioned with electronic door lock, individual safe, Iron & Iron Board, Tea / Coffee maker, Mini bar, Multi - channel Music, Weighing scale, Free morning Newspaper, Sewing kit, Wake up facility and Car-on Hire.
         <br/>
         <br/>
         Luxury Rooms are truly grand and beautiful and Deluxe Rooms are also truly comfortable. Once you enter these rooms you will be charmed by the decor and feel contented to see the world-class amenities. Special care has been taken for your personal comforts and to fulfill professional needs. Here you will find Writing desk with fine stationary, Internet connectivity. Modern toiletries will take care of your hygiene and sense of well-being. Guests here get complementary sumptuous breakfast, so that they could begin day in proper mood. All luxury rooms open into beautiful private gardens.
         <br/>
         <br/>
         Please contact Reservation for Tariff Plan as it Change on daily Basis. You can send an Email info@airportresidency.com or call us 011-47122222.
         <br/></p>
  </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Room;