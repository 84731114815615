import React from "react";
// reactstrap components
// import {
// } from "reactstrap";

// core components
import Navbar from "../components/Navbars/NewNavbar";
import PageHeader from "../components/Headers/PageNotFound";
import DarkFooter from "../components/Footers/DarkFooter";

// sections for this page

import {Helmet} from "react-helmet";

function AboutUs () {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <Helmet>
        <title>404 | Airport Residency</title>
        <meta name="description" content="404 : Error page Not Found" />
        <meta name="keywords" cpntent="Airport Residency" />
      </Helmet>
      <Navbar />
      <div className="wrapper">
        <PageHeader />
        <DarkFooter />
      </div> 
    </>
  );
}


export default AboutUs;