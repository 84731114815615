/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/css/now-ui-kit.css";

// pages for this kit
import Home from "./pages/Home"
import AboutUs from "./pages/AboutUs";
import Rooms from "./pages/Rooms"
import HotelGallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import PageNotfound from "./pages/PageNotFound"


ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/AboutUs">
        <AboutUs />
      </Route>
      <Route path="/Rooms">
        <Rooms />
      </Route>
      <Route path="/Gallery">
        <HotelGallery />
      </Route>
      <Route path="/ContactUs">
        <ContactUs />
      </Route>
      <Route path="/PageNotFound">
        <PageNotfound />
      </Route>

        <Redirect from="/index.html" to="/" />
        <Redirect from="/about-us.html" to="/AboutUs" />
        <Redirect from="/rooms.html" to="/Rooms" />
        <Redirect from="/gallery.html" to="/Gallery" />
        <Redirect from="/reach-us.html" to="/ContactUs" />
        <Redirect from="/404.html" to="/PageNotFound" />

        <Redirect to="/PageNotFound" />

    </Switch>

  </Router>,
  document.getElementById("root")
);
