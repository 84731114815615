/*eslint-disable*/
import React from "react";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";


function DarkFooter() {
  return (
    <footer className="footer" data-background-color="maroon">
      <Container>
        <nav>
        <div className="copyright" id="copyright">
        © {new Date().getFullYear()}, {" "}
          <a
            href="/"
          >
            Airport Residency.
          </a>
          .  All Rights Reserved.
          </div>
        </nav>
        <div className="copyright" id="copyright">
Designed & Coded by{" "}
          <a
            href="https://www.bigit.co"
            target="_blank"
          >
            BIG IT
          </a>
          .
        </div>
        </Container>
    </footer>
  );
}

export default DarkFooter;
